<template>
	<div>
		<CRow>
			<CCol>
				<CCard>
					<CCardHeader>
						Patient List
					</CCardHeader>
					<CCardBody>
						<CRow>
							<CCol md="4">
								<CInput
									label="Filter"
									placeholder="Enter Filter"
									v-model="moreParams.filter"
								/>
							</CCol>
							<CCol md="4">
								<div role="group" class="form-group">
									<CButton
										@click="reset"
										id="btn"
										color="primary"
										style="margin-top:27px;"
									>
										<CIcon name="cil-reload" />
									</CButton>
									<CButton
										@click="add()"
										id="btn"
										color="success"
										style="margin-top:27px; margin-left:5px;"
									>
										<CIcon name="cil-plus" />
									</CButton>
								</div>
							</CCol>
						</CRow>
						<div class="table-responsive" style="max-height:900px;">
							<vuetable
								v-show="!loadingTable"
								ref="vuetable"
								:api-url="uri"
								:fields="fields"
								:per-page="15"
								pagination-path="pagination"
								data-path="mydata"
								:css="cssVuetable.table"
								:transform="transformData"
								:append-params="moreParams"
								:http-options="httpoption"
								@vuetable:pagination-data="onPaginationData"
								@vuetable:loading="onLoadingTable"
								@vuetable:load-success="onLoadSuccessTable"
							>
								<template slot="slot-actions" slot-scope="props">
									<CButton
										@click="add(props.rowData)"
										color="warning"
										size="sm"
										class="py-0"
									>
										<CIcon name="cil-pencil" />
									</CButton>
								</template>
							</vuetable>
							<div v-show="loadingTable" class="text-center">
								<ellipsis-loader :color="'#54f1d2'" />
							</div>
						</div>
						<vuetable-pagination
							ref="pagination"
							:css="cssVuetable.pagination"
							@vuetable-pagination:change-page="onChangePage"
						>
						</vuetable-pagination>
					</CCardBody>
				</CCard>
			</CCol>
		</CRow>

		<CModal
			:show.sync="modal"
			:no-close-on-backdrop="true"
			:centered="true"
			title="Modal title 2"
			size="lg"
			color="info"
		>
			<div v-if="!selectedCons">
				<CRow>
					<CCol md="12">
						<CInput
							label="Customer"
							@keydown="getCustomer($event)"
							placeholder="type customer name / number"
						/>
					</CCol>
				</CRow>
				<CRow>
					<div v-show="loadingCustomer" class="text-center col-md-12">
						<ellipsis-loader :color="'#54f1d2'" />
					</div>
					<div v-show="!loadingCustomer" class="table-responsive">
						<table class="table table-striped">
							<thead>
								<tr>
									<th>Name</th>
									<th>Number</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(cust, i) in customers" :key="cust.id">
									<td>{{ cust.name }}</td>
									<td>{{ cust.number }}</td>
									<td>
										<CButton
											@click="selectCons(i)"
											color="secondary"
											shape="pill"
										>
											SELECT
										</CButton>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</CRow>
			</div>
			<div v-else>
				<CRow>
					<CCol md="12">
						<label for="">Customer :</label><br />
						<h4>{{ selectedCons.name }} - {{ selectedCons.number }}</h4>
						<hr />
					</CCol>
				</CRow>
				<CRow>
					<CCol md="12">
						<CInput label="Name" v-model="frm.name" placeholder="Name" />
					</CCol>
				</CRow>
				<CRow>
					<CCol md="6">
						<CInput
							label="DOB"
							type="date"
							v-model="frm.dob"
							placeholder="DOB"
						/>
					</CCol>
					<CCol md="6">
						<div role="group" class="form-group">
							<label for="sexe" class=""> Sexe </label>
							<select v-model="frm.sexe" id="sexe" class="form-control">
								<option data-key="0" value="P"> P </option>
								<option data-key="1" value="L"> L </option>
							</select>
						</div>
					</CCol>
					<CCol md="6">
						<div role="group" class="form-group">
							<label for="insurance" class=""> Insurance </label>
							<select
								v-model="frm.insurance"
								id="insurance"
								class="form-control"
							>
								<option
									v-for="(ins, i) in insurance"
									:key="i"
									:data-key="ins"
									:value="ins"
								>
									{{ ins }}
								</option>
							</select>
						</div>
					</CCol>
					<CCol md="6">
						<CInput
							label="Insurance Number"
							v-model="frm.insuranceNumber"
							placeholder="insurance number"
						/>
					</CCol>
					<CCol md="6">
						<div role="group" class="form-group">
							<label for="province" class=""> Province </label>
							<select
								v-model.number="frm.provinceId"
								id="province"
								class="form-control"
								@change="getCity($event)"
							>
								<option
									v-for="(pro, i) in provinces"
									:key="i"
									:data-key="pro.id"
									:value="pro.id"
								>
									{{ pro.name }}
								</option>
							</select>
						</div>
					</CCol>
					<CCol md="6">
						<div role="group" class="form-group">
							<label for="city" class=""> City</label>
							<select
								v-show="!loadingCity"
								v-model.number="frm.cityId"
								id="city"
								class="form-control"
							>
								<option
									v-for="(reg, i) in regencies"
									:key="i"
									:data-key="reg.id"
									:value="reg.id"
								>
									{{ reg.name }}
								</option>
							</select>
							<ellipsis-loader v-show="loadingCity" :color="'#54f1d2'" />
						</div>
					</CCol>
				</CRow>
				<CRow>
					<CCol md="12">
						<CInput
							label="Address"
							v-model="frm.address"
							placeholder="address"
						/>
					</CCol>
				</CRow>
			</div>
			<template #header>
				<h6 class="modal-title">
					{{ selectedId != null ? "Edit " + frm.name : "Add new data" }}
				</h6>
				<CButtonClose @click="modal = false" class="text-white" />
			</template>
			<template #footer>
				<CButton @click="modal = false" color="danger">CANCEL</CButton>
				<CButton @click="save" :disabled="loading" color="success"
					>SAVE</CButton
				>
			</template>
		</CModal>
	</div>
</template>

<script>
import { basuri, debounce, insurance } from "@/plugins/utils";

export default {
	data() {
		return {
			uri: basuri + "patient",
			moreParams: { filter: null },
			selectedId: null,
			selectedCons: null,
			customers: [],
			provinces: [],
			regencies: [],
			loadingCustomer: false,
			loadingCity: false,
			loading: false,
			modal: false,
			insurance,
			frm: {
				consumerId: "",
				name: "",
				dob: new Date(Date.now()).toISOString().split("T")[0],
				sexe: "L",
				insuranceNumber: "",
				insurance: "",
				provinceId: "",
				cityId: "",
				address: "",
			},
			fields: [
				{
					name: "consumer.name",
					title: "Consumer",
					sortField: "consumer.name",
				},
				{
					name: "name",
					title: "Name",
					sortField: "name",
				},
				{
					name: "number",
					title: "Number",
					sortField: "number",
				},
				{
					name: "sexe",
					title: "Sexe",
					sortField: "sexe",
				},
				{
					name: "dob",
					title: "DOB",
					sortField: "dob",
				},
				{
					name: "regency.name",
					title: "City",
					sortField: "regency.name",
				},
				{
					name: "slot-actions",
					title: "",
				},
			],
		};
	},
	watch: {
		"moreParams.filter": debounce(function(val) {
			if (val.length > 3 || val.length == 0) this.$refs.vuetable.refresh();
		}, 1000),
	},
	mounted() {
		this.getProvince();
	},
	methods: {
		reset() {
			this.moreParams = { filter: null };
			this.$refs.vuetable.refresh();
		},
		async add(data = null) {
			if (data) {
				this.frm = JSON.parse(JSON.stringify(data));
				this.selectedId = data.id;
				this.selectedCons = this.frm.consumer;
				await this.getCity({ target: { value: data.provinceId } });
			} else {
				this.frm = {
					consumerId: "",
					name: "",
					dob: new Date(Date.now() - 15778476 * 100000)
						.toISOString()
						.split("T")[0],
					sexe: "L",
					insuranceNumber: "",
					insurance: "",
					provinceId: "",
					cityId: "",
					address: "",
				};
				this.selectedId = null;
				this.selectedCons = null;
			}
			this.modal = true;
		},
		save: debounce(async function() {
			this.loading = true;
			this.frm.consumerId = this.selectedCons.id;
			try {
				let datar = {};
				if (this.selectedId) {
					const { data } = await this.$http.put(
						"patient/" + this.selectedId,
						this.frm
					);
					datar = data;
				} else {
					const { data } = await this.$http.post("patient", this.frm);
					datar = data;
				}
				if (datar.status == "success") {
					this.$toast.success("Success ! " + datar.message);
					this.$refs.vuetable.refresh();
					this.modal = false;
				} else {
					this.$swal("Fail !", datar.message, "error");
				}
			} catch (error) {
				this.$swal("Error !", error.message, "error");
			}
			this.loading = false;
		}, 1000),

		getCustomer: debounce(async function(val) {
			this.loadingCustomer = true;
			const { data } = await this.$http.get("customer", {
				params: { filter: val.target.value, page: 1, per_page: 5 },
			});
			this.loadingCustomer = false;
			this.customers = data.data.data;
		}, 1000),

		getProvince: debounce(async function() {
			const { data } = await this.$http.get("province");
			this.provinces = data.data.rows;
		}, 1000),

		getCity: debounce(async function(e) {
			this.loadingCity = true;
			if (!this.frm.id) this.frm.cityId = "";
			const { data } = await this.$http.get("regency/" + e.target.value);
			this.regencies = data.data.rows;
			this.loadingCity = false;
		}, 1000),

		selectCons(i) {
			this.selectedCons = this.customers[i];
		},
	},
};
</script>
